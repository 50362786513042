import { Box, Card, CardContent, Link, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

import VideoPracticeInput from '../components/VideoPracticeInput';
import { matchSuggestion } from '../lesson/utils';
import VideoRecapEditor from './editors/VideoRecapEditor';
import VideoTimelineEditor from './editors/VideoTimelineEditor';

const VideoTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Video &quot;
      {record.name}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { marginRight: 10 },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const secondsToms = (d: number) => {
  d = Number(d);
  const m = Math.floor(d / 60);
  const s = Math.floor(d % 60);

  const mDisplay = m > 0 ? `${m}m ` : '';
  const sDisplay = s > 0 ? `${s}s` : '';
  return mDisplay + sDisplay;
};

const VideoForm = (props: any) => {
  const classes = useStyles(props);
  const { record } = props;

  const [error, setError] = React.useState<any>();
  const [showTimeline, setShowTimeline] = React.useState<any>(false);

  // const onError = (newError: any) => {
  //   setError(newError?.message);
  // };

  React.useEffect(() => {
    if (record?.url) {
      setError(undefined);
    }
  }, [record?.url]);

  React.useEffect(() => {
    setTimeout(() => {
      setShowTimeline(true);
    }, 500);
  }, []);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
                    <TextInput
                      autoFocus
                      source="name"
                      label="Video Name"
                      fullWidth
                    />
                  </Box>
                  <Box mr="1em">
                    <TextInput source="tags" label="Tags" helperText={false} />
                  </Box>
                  <Box>
                    <SelectInput
                      labelId="select_hosting_provider_label"
                      id="select_hosting_provider"
                      source="hosting_provider"
                      defaultValue="vimeo"
                      style={{ minWidth: 200 }}
                      choices={[
                        { id: 'vimeo', name: 'vimeo' },
                        { id: 'muse', name: 'muse' },
                      ]}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box flex={1} mr="1em">
                    <TextInput source="url" label="Vimeo Video ID" fullWidth />

                    <TextInput
                      source="muse_url"
                      label="Muse HLS m3u8 URL"
                      className={classes.url}
                      fullWidth
                    />

                    <TextInput
                      source="muse_video_id"
                      label="Muse Video ID"
                      className={classes.url}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                  <Box mr="1em">
                    <TextInput
                      source="thumbnail_url"
                      label="Cached Thumbnail URL"
                      helperText={false}
                      fullWidth
                    />
                  </Box>

                  <Box mr="1em">
                    <NumberInput
                      source="duration"
                      label="Cached Duration in secs"
                      helperText={false}
                      disabled
                    />
                  </Box>

                  <Box>
                    <ReferenceInput
                      allowEmpty
                      label="Coach"
                      source="coachId"
                      reference="coach"
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </Box>
                </Box>

                <Box style={{ marginBottom: 10 }}>
                  <JsonField
                    source="subtitles"
                    record={record}
                    jsonString={false}
                    reactJsonOptions={{
                      name: 'Subtitles',
                      collapsed: true,
                      enableClipboard: true,
                      displayDataTypes: false,
                      sortKeys: true,
                      displayObjectSize: false,
                    }}
                  />
                </Box>

                <Box>
                  {record?.thumbnail_url && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <b>Thumbnail Image</b>
                      <img
                        alt=""
                        src={record?.thumbnail_url}
                        style={{ width: 150 }}
                      />
                    </div>
                  )}
                </Box>

                {record?.muse_url && !error && (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Video{' '}
                      <Link
                        href={`https://muse.ai/e/${record?.muse_video_id}`}
                        target="_blank"
                      >
                        (Manage on Muse)
                      </Link>
                    </Typography>
                  </Box>
                )}

                {error && (
                  <Typography variant="body1" gutterBottom>
                    Video Error: {error}
                  </Typography>
                )}

                <Box style={{ margin: '1rem .25rem' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ minWidth: 80 }}>Height</td>
                        <td>{props?.record?.height}</td>
                      </tr>
                      <tr>
                        <td>Width</td>
                        <td>{props?.record?.width}</td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>
                          {secondsToms(props?.record?.duration || 0)} (
                          {props?.record?.duration} seconds)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>

                <Box>
                  <ReferenceArrayInput
                    filterToQuery={(searchText: any) => ({ word: searchText })}
                    label="Practice Items"
                    source="practiceId"
                    reference="content/practice"
                    filter={{ isUserGeneratedContent: false }}
                    fullWidth
                  >
                    <VideoPracticeInput>
                      <AutocompleteArrayInput
                        optionValue="id"
                        optionText={(wordRecord: any) =>
                          `${wordRecord?.word} ${
                            wordRecord?.coachSample ? ` ✅` : ` ❌`
                          } (id: ${wordRecord?.id?.slice(0, 4)})`
                        }
                        allowDuplicates
                        matchSuggestion={(filterValue: string, choice: any) =>
                          matchSuggestion(filterValue, choice, 'word')
                        }
                      />
                    </VideoPracticeInput>
                  </ReferenceArrayInput>
                </Box>
                <Box>
                  {showTimeline && <VideoTimelineEditor record={record} />}
                </Box>
                <Box>
                  <VideoRecapEditor record={record} />
                </Box>
              </Box>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="videos"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const VideoEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<VideoTitle />} {...props}>
    <VideoForm />
  </Edit>
);

export default VideoEdit;
