import { Box } from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import StyleIcon from '@material-ui/icons/Style';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ViewListIcon from '@material-ui/icons/ViewList';
import * as React from 'react';
import { FC, useState } from 'react';
import { DashboardMenuItem, MenuItemLink, MenuProps } from 'react-admin';
import { useSelector } from 'react-redux';

import adsTracking from '../adTracking';
import assessment from '../assessment';
import assessmentstaken from '../assessmentstaken';
import audiologs from '../audiologs';
import cancellationreport from '../cancellationreport';
import coach from '../coach';
import coachQuips from '../coachQuips';
import contactus from '../contactus';
import conversation from '../conversation';
import curriculum from '../curriculum';
import dailylesson from '../dailylesson';
import emaillogs from '../emaillogs';
import engagement from '../engagement';
// import referrals from '../referrals'; // dashboard
import generativeConv from '../gen_conv';
import genConvGoalTemplate from '../gen_conv/goal_template';
import topicConfig from '../gen_conv/topic_config';
import issuereport from '../issuereport';
import lesson from '../lesson';
import mouthDiagram from '../mouthDiagram';
import mouthDiagramSet from '../mouthDiagramSet';
import multiplechoice from '../multipleChoice';
import notificationlogs from '../notificationlogs';
import phoneme from '../phoneme';
import practice from '../practice';
import provisionalusers from '../provisionalusers';
import rapidfire from '../rapidfire';
import referralCodes from '../referrals/codes';
import referralCodeRedemptions from '../referrals/redemptions';
import skills from '../skills';
import song from '../song';
import songstaken from '../songstaken';
import tutorialConfig from '../tutorialConfig';
import uniquecodes from '../uniquecodes';
import unit from '../unit';
import userimprovement from '../userimprovement';
import users from '../users';
import userstats from '../userstats';
import video from '../video';
import videoGroups from '../videoGroups';
import videofeedback from '../videofeedback';
import words from '../words';
import SubMenu from './SubMenu';

type MenuName =
  | 'menuUsers'
  | 'menuContent'
  | 'menuLogs'
  | 'menuDLPContent'
  | 'aiChat'
  | 'foundationalContent'
  | 'feedbackSupport';

const Menu: FC<MenuProps> = ({ onMenuClick, dense = false }) => {
  const [menuState, setMenuState] = useState({
    menuUsers: true,
    menuContent: true,
    menuLogs: true,
    menuDLPContent: true,
    aiChat: true,
    foundationalContent: true,
    feedbackSupport: true,
  });

  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  useSelector((state: any) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setMenuState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={1}>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink
        to="/engagement"
        primaryText="Engagement"
        leftIcon={<engagement.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/ads-tracking"
        primaryText="Ads Tracking"
        leftIcon={<adsTracking.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuUsers')}
        isOpen={menuState.menuUsers}
        sidebarIsOpen={open}
        name="Users"
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/users"
          primaryText="Users"
          leftIcon={<users.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/provisional_users"
          primaryText="Provisional Users"
          leftIcon={<provisionalusers.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/user_stats"
          primaryText="User Stats"
          leftIcon={<userstats.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/phonetic-search"
          primaryText="Audio Search"
          leftIcon={<MUIIcons.Search />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/skill-assessments"
          primaryText="Skill Assessments"
          leftIcon={<MUIIcons.Timeline />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuDLPContent')}
        isOpen={menuState.menuDLPContent}
        sidebarIsOpen={open}
        name="User Journey"
        icon={<curriculum.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/g/skills"
          primaryText="Skills"
          leftIcon={<skills.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/skill-paths-display"
          primaryText="Skill Paths"
          leftIcon={<StyleIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/g/videos"
          primaryText="Video Groups"
          leftIcon={<videoGroups.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/curriculum-playground"
          primaryText="Playground"
          leftIcon={<BuildIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/curriculum-explorer"
          primaryText="Explorer"
          leftIcon={<ViewListIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/skills-text-scan"
          primaryText="Skills Text Scan"
          leftIcon={<TrackChangesIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('foundationalContent')}
        isOpen={menuState.foundationalContent}
        sidebarIsOpen={open}
        name="Foundations"
        icon={<curriculum.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/content/video"
          primaryText="Videos"
          leftIcon={<video.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/practice"
          primaryText="Practice"
          leftIcon={<practice.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/conversation"
          primaryText="Conversations"
          leftIcon={<conversation.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/content/assessment"
          primaryText="Assessments"
          leftIcon={<assessment.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/phoneme"
          primaryText="Phonemes"
          leftIcon={<phoneme.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/coach"
          primaryText="Coachs"
          leftIcon={<coach.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/mouthDiagramSet"
          primaryText="Mouth Diagram Set"
          leftIcon={<mouthDiagramSet.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/mouthDiagram"
          primaryText="Mouth Diagrams"
          leftIcon={<mouthDiagram.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/g/words"
          primaryText="Dictionary"
          leftIcon={<words.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuContent')}
        isOpen={menuState.menuContent}
        sidebarIsOpen={open}
        name="Legacy Content"
        icon={<curriculum.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/content/curriculum"
          primaryText="Curriculums"
          leftIcon={<curriculum.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/unit"
          primaryText="Units"
          leftIcon={<unit.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/lesson"
          primaryText="Lessons"
          leftIcon={<lesson.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/content/rapidfire"
          primaryText="Rapid Fire"
          leftIcon={<rapidfire.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/multiplechoice"
          primaryText="Multiple Choice"
          leftIcon={<multiplechoice.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/content/quips"
          primaryText="Quips"
          leftIcon={<coachQuips.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/tutorial_config"
          primaryText="Tutorial Config"
          leftIcon={<tutorialConfig.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/content/song"
          primaryText="Songs"
          leftIcon={<song.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('aiChat')}
        isOpen={menuState.aiChat}
        sidebarIsOpen={open}
        name="AI Chat"
        icon={<audiologs.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/content/generative_conversation/topic_config"
          primaryText="AI Scenarios"
          leftIcon={<topicConfig.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/generative_conversation/goal_template"
          primaryText="Goal Templates"
          leftIcon={<genConvGoalTemplate.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/logs/generative_conversation"
          primaryText="Chat Logs"
          leftIcon={<generativeConv.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('feedbackSupport')}
        isOpen={menuState.feedbackSupport}
        sidebarIsOpen={open}
        name="Feedback & Support"
        icon={<audiologs.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/videofeedback"
          primaryText="Video Feedback"
          leftIcon={<videofeedback.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/contact_us"
          primaryText="Contact Us"
          leftIcon={<contactus.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/issue_report"
          primaryText="Issue Reports"
          leftIcon={<issuereport.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/cancellation_report"
          primaryText="Cancellation Reports"
          leftIcon={<cancellationreport.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuLogs')}
        isOpen={menuState.menuLogs}
        sidebarIsOpen={open}
        name="Logs"
        icon={<audiologs.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/audiologs"
          primaryText="Audio Logs"
          leftIcon={<audiologs.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/assessmentstaken"
          primaryText="Assessments Taken"
          leftIcon={<assessmentstaken.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/songstaken"
          primaryText="Songs Sung"
          leftIcon={<songstaken.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/content/dailylesson"
          primaryText="Daily Goals"
          leftIcon={<dailylesson.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to="/emaillogs"
          primaryText="Email Logs"
          leftIcon={<emaillogs.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/notificationlogs"
          primaryText="Notification Logs"
          leftIcon={<notificationlogs.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/uniquecodes"
          primaryText="Unique Codes"
          leftIcon={<uniquecodes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/userimprovement"
          primaryText="User Improvement"
          leftIcon={<userimprovement.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/logs/referral_codes"
          primaryText="Referral Codes"
          leftIcon={<referralCodes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/logs/referral_code_redemptions"
          primaryText="Redemptions"
          leftIcon={<referralCodeRedemptions.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
