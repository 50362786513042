import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  PasswordInput,
  required,
  email,
} from 'react-admin';
import { AnyObject } from 'react-final-form';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
  first_name: { display: 'inline-block' },
  last_name: { display: 'inline-block', marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: 'inline-block' },
  city: { display: 'inline-block', marginLeft: 32 },
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  password: { display: 'inline-block' },
  confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
  password,
  confirm_password,
}: AnyObject) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      'The password confirmation is not the same as the password';
  }

  if (password && !confirm_password) {
    errors.confirm_password = 'Please confirm the password for this user';
  }

  if (!password && confirm_password) {
    errors.password = 'Missing password value';
  }

  return errors;
};

const UserCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm autoComplete="off" validate={validatePasswords}>
        <SectionTitle label="Identity" />
        <TextInput
          autoFocus
          source="firstName"
          formClassName={classes.first_name}
          validate={requiredValidate}
        />
        <TextInput source="lastName" formClassName={classes.last_name} />
        <TextInput
          type="email"
          source="email"
          validation={{ email: true }}
          fullWidth
          formClassName={classes.email}
          validate={[required(), email()]}
          autoComplete="prayer"
        />
        <Separator />
        <SectionTitle label="Password" />
        <SubTitle label="Leave blank for user to set their own password" />
        <PasswordInput
          autoComplete="new-password"
          source="password"
          formClassName={classes.password}
        />
        <PasswordInput
          source="confirm_password"
          formClassName={classes.confirm_password}
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => (
  <Typography variant="h6">{label}</Typography>
);

const SubTitle = ({ label }: { label: string }) => (
  <Typography variant="subtitle1" gutterBottom>
    {label}
  </Typography>
);

const Separator = () => <Box pt="1em" />;

export default UserCreate;
