import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import MUISkeleton from '@material-ui/lab/Skeleton';
import graphql from 'babel-plugin-relay/macro';
import * as d3 from 'd3';
import * as df from 'date-fns';
import _ from 'lodash';
import { useCallback } from 'react';
import React, { useState } from 'react';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation } from 'react-router-dom';

import IPAText from '../components/IPAText';
import PlotContainer, { Plot } from '../components/PlotContainer';
import UserSelect from '../components/UserSelect';
import { getScaleColor } from '../gen_conv/GenConvShow';
import { SkillAssessmentExample } from './DLPAssessmentsTaken2';

const SkillAssessments = () => {
  const query = useQuery();

  const [userID, setUserID] = useState<string | null>(
    query.get('userID') ?? null,
  );

  const onUserSelect = React.useCallback((userID: string) => {
    query.set('userID', userID);
    window.location.hash = `/skill-assessments?${query.toString()}`;
    setUserID(userID);
  }, []);

  const [
    commitRegenerateUserContinuousAssessments,
    isRegenerateUserContinuousAssessmentsPending,
  ] = useMutation(graphql`
    mutation SkillAssessments_RegenerateUserContinuousAssessments_Mutation(
      $userID: ID!
    ) {
      regenerateUserContinuousAssessments(userID: $userID) {
        ...SkillAssessments_user
      }
    }
  `);

  return (
    <MUI.Box>
      <MUI.Box
        marginBottom={2}
        display="flex"
        gridColumnGap={20}
        marginTop={2.5}
      >
        <MUI.Box style={{ maxWidth: 600, flexGrow: 1 }}>
          <UserSelect onChange={onUserSelect} value={userID} />
        </MUI.Box>

        <MUI.Button
          onClick={() => {
            commitRegenerateUserContinuousAssessments({
              variables: { userID },
            });
          }}
          variant="outlined"
          size="medium"
          color="secondary"
          startIcon={
            isRegenerateUserContinuousAssessmentsPending ? (
              <MUI.CircularProgress size={16} />
            ) : (
              <MUIIcons.Refresh />
            )
          }
          disabled={!userID || isRegenerateUserContinuousAssessmentsPending}
        >
          Regenerate Continuous Assessments
        </MUI.Button>
      </MUI.Box>

      {userID && (
        <React.Suspense fallback={<MUI.CircularProgress />}>
          <SkillAssessmentsInner userID={userID} key={userID} />
        </React.Suspense>
      )}
    </MUI.Box>
  );
};

const SkillAssessmentsInner = ({ userID }) => {
  const queryRef = useLazyLoadQuery(
    graphql`
      query SkillAssessments_Query($userID: ID!) {
        userByID(id: $userID) {
          ...SkillAssessments_user
        }
      }
    `,
    { userID },
  ) as any;

  const user = useFragment(
    graphql`
      fragment SkillAssessments_user on User {
        createdAt

        skills {
          skill {
            id
          }

          skillAssessments {
            createdAt
          }

          ...SkillAssessments_Skill_userSkill
        }

        ...SkillAssessments_OverallSkill_user
        ...SkillAssessments_Skill_user
      }
    `,
    queryRef.userByID,
  );

  const maxDate = React.useMemo(
    () =>
      _.maxBy(
        user.skills.flatMap((s) =>
          s.skillAssessments.map((sa) => new Date(sa.createdAt)),
        ),
        (d: Date) => d.getTime(),
      ),
    [user.skills],
  );

  return (
    <MUI.Box display="flex" flexDirection="column" gridRowGap={10}>
      <OverallSkill user={user} minDate={user.createdAt} maxDate={maxDate} />

      {user.skills.map((userSkill) => (
        <Skill
          key={userSkill.skill.id}
          userSkill={userSkill}
          user={user}
          minDate={user.createdAt}
          maxDate={maxDate}
        />
      ))}
    </MUI.Box>
  );
};

const OverallSkill = ({ user: userRef, minDate, maxDate }) => {
  const user = useFragment(
    graphql`
      fragment SkillAssessments_OverallSkill_user on User {
        aggregateAssessments {
          createdAt
          bottomAvg
        }

        accurateAggregateAssessments {
          createdAt
          bottomAvg
        }
      }
    `,
    userRef,
  );

  const drawPlot = useCallback(
    ({ width, height }) => {
      const data = user.accurateAggregateAssessments.map((d) => ({
        ...d,
        createdAt: new Date(d.createdAt),
      }));

      const data2 = user.aggregateAssessments.map((d) => ({
        ...d,
        createdAt: new Date(d.createdAt),
      }));

      const plotMinScore = 0;
      const plotMaxScore = 1.08;

      return Plot.plot({
        width,
        height,
        marginBottom: 40,
        marginRight: 0,
        x: {
          label: 'assessment date',
          type: 'time',
          domain: [minDate, maxDate],
          insetLeft: 3,
          insetRight: 3,
        },
        y: { domain: [plotMinScore, plotMaxScore] },
        marks: [
          // Color guidelines
          Plot.ruleY([0.9, 0.8, 0.6, 0.4], {
            stroke: (d) => getScaleColor(d * 100),
            opacity: 0.5,
            strokeDasharray: '5 5',
          }),

          Plot.ruleX([user.createdAt], {
            y1: plotMinScore,
            y2: 1,
            opacity: 0.25,
          }),

          Plot.differenceY(data, {
            x: 'createdAt',
            y2: (d) => d.bottomAvg,
            y1: data[0]?.bottomAvg ?? 0,
            fillOpacity: 0.75,
            negativeFill: 'red',
            curve: 'monotone-x',
            clip: true,
          }),

          Plot.line(data2, {
            x: 'createdAt',
            y: 'bottomAvg',
            stroke: MUIColors.grey[500],
            curve: 'monotone-x',
            clip: true,
            strokeDasharray: '2 4',
          }),
        ],
      });
    },
    [user, minDate, maxDate],
  );

  return (
    <>
      <MUI.Paper variant="outlined">
        <MUI.Accordion
          TransitionProps={{ unmountOnExit: true }}
          defaultExpanded
        >
          <UnstyledAccordionSummary>
            <MUI.Box padding={1}>
              <MUI.Typography variant="body2">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span
                  style={{
                    userSelect: 'text',
                    cursor: 'text',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  Overall
                </span>
              </MUI.Typography>
            </MUI.Box>
          </UnstyledAccordionSummary>
          <UnstyledAccordionDetails>
            <MUI.Box width="100%" padding={1} paddingTop={0}>
              <MUI.Divider style={{ marginBottom: 5 }} />
              <MUI.Box display="flex" flexDirection="row" gridColumnGap={15}>
                <PlotContainer
                  drawPlot={drawPlot}
                  style={{ height: 250, flexGrow: 1 }}
                />
                <MUI.Box
                  width={250}
                  style={{ overflow: 'hidden', display: 'flex' }}
                >
                  <MUI.Box style={{ alignSelf: 'center', width: '100%' }}>
                    <MUI.Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ textAlign: 'center' }}
                    >
                      No additional details.
                    </MUI.Typography>
                  </MUI.Box>
                </MUI.Box>
              </MUI.Box>
            </MUI.Box>
          </UnstyledAccordionDetails>
        </MUI.Accordion>
      </MUI.Paper>
    </>
  );
};

const Skill = ({
  user: userRef,
  userSkill: userSkillRef,
  minDate,
  maxDate,
}) => {
  const user = useFragment(
    graphql`
      fragment SkillAssessments_Skill_user on User {
        createdAt
      }
    `,
    userRef,
  );

  const userSkill = useFragment(
    graphql`
      fragment SkillAssessments_Skill_userSkill on UserSkill {
        skill {
          id
          name
        }

        skillAssessments {
          id
          createdAt
          score
          minScore
          maxScore
          sampleSize

          assessmentTakenID
        }
      }
    `,
    userSkillRef,
  );

  const drawPlot = useCallback(
    ({ width, height }) => {
      const data = userSkill.skillAssessments
        .filter((d) => d.assessmentTakenID == null)
        .map((d) => ({
          ...d,
          createdAt: new Date(d.createdAt),
        }));

      const directAssessments = userSkill.skillAssessments
        .filter((d) => d.assessmentTakenID != null)
        .map((d) => ({
          ...d,
          createdAt: new Date(d.createdAt),
        }));

      const plotMinScore = 0;
      const plotMaxScore = 1.08;

      return Plot.plot({
        width,
        height,
        marginBottom: 40,
        marginRight: 0,
        x: {
          label: 'assessment date',
          type: 'time',
          domain: [minDate, maxDate],
          insetLeft: 3,
          insetRight: 3,
        },
        y: { domain: [plotMinScore, plotMaxScore] },
        marks: [
          // Color guidelines
          Plot.ruleY([0.9, 0.8, 0.6, 0.4], {
            stroke: (d) => getScaleColor(d * 100),
            opacity: 0.5,
            strokeDasharray: '5 5',
          }),

          Plot.ruleX([user.createdAt], {
            y1: plotMinScore,
            y2: 1,
            opacity: 0.25,
          }),

          // Credible interval
          Plot.areaY(data, {
            x: 'createdAt',
            y1: 'minScore',
            y2: 'maxScore',
            fillOpacity: 0.15,
            curve: 'monotone-x',
            clip: true,
          }),

          // Pointer rules
          Plot.ruleX(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y1: plotMinScore,
              y2: plotMaxScore,
              opacity: 0.15,
            }),
          ),
          Plot.ruleX(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y1: 'minScore',
              y2: 'maxScore',
              clip: true,
              marker: 'tick',
            }),
          ),
          Plot.text(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y: 'maxScore',
              dy: -5,
              frameAnchor: 'bottom',
              text: (d) => d.maxScore.toFixed(2),
            }),
          ),

          // Pointer text
          Plot.text(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y: 'minScore',
              dy: 8,
              frameAnchor: 'top',
              text: (d) => d.minScore.toFixed(2),
              clip: true,
            }),
          ),

          // Main line
          Plot.line(data, {
            x: 'createdAt',
            y: 'score',
            stroke: 'black',
            marker: true,
            curve: 'monotone-x',
            clip: true,
          }),

          // Direct assessments
          Plot.ruleX(directAssessments, {
            x: 'createdAt',
            y1: 'minScore',
            y2: 'maxScore',
            clip: true,
            stroke: MUIColors.blue[700],
            marker: 'tick',
          }),
          Plot.dot(directAssessments, {
            x: 'createdAt',
            y: 'score',
            fill: MUIColors.blue[700],
            stroke: 'white',
            clip: true,
          }),

          // data-pointer-id tip
          Plot.tip(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y: 'score',
              title: (d) => d,
              render: (
                index,
                scales,
                values: any,
                dimensions,
                context,
                next: any,
              ) => {
                const g = next(index, scales, values, dimensions, context);
                const [i] = index;
                if (i === undefined) return g;

                const data = values.title[i];

                d3.select(g)
                  .attr('display', 'none')
                  .select(`g[aria-label="tip"] g`)
                  .attr('data-pointer-id', data.id);

                return g;
              },
            }),
          ),

          // Tips
          Plot.tip(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y: plotMinScore,
              anchor: 'top-right',
              pointerSize: 0,
              title: (d) => df.format(d.createdAt, 'MMM d, yyyy, h:mm a'),
              stroke: 'transparent',
              fill: '#fffeead9',
              pathFilter: 'none',
            }),
          ),
          Plot.tip(
            data,
            Plot.pointerX({
              x: 'createdAt',
              y: plotMaxScore,
              anchor: 'bottom-right',
              pointerSize: 0,
              title: (d) => `Score: ${d.score.toFixed(2)}
Samples: ${d.sampleSize}`,
              stroke: 'transparent',
              fill: '#fffeead9',
              pathFilter: 'none',
            }),
          ),
          // Plot.ruleY([0]),
        ],
      });
    },
    [userSkill, user, minDate, maxDate],
  );

  const [pointerID, setPointerID] = useState<string | null>(null);

  const defaultExpanded = React.useMemo(
    () =>
      _.sumBy(userSkill.skillAssessments, (d: any) =>
        d.score < 0.9 ? 1 : 0,
      ) >= 2,
    [userSkill.skillAssessments],
  );

  const lastContinuousAssessment = React.useMemo(
    () =>
      userSkill.skillAssessments
        .filter((d) => d.assessmentTakenID == null)
        .slice(-1)[0],
    [userSkill.skillAssessments],
  );

  return (
    <>
      <MUI.Paper variant="outlined">
        <MUI.Accordion
          TransitionProps={{ unmountOnExit: true }}
          defaultExpanded={defaultExpanded}
        >
          <UnstyledAccordionSummary>
            <MUI.Box padding={1}>
              <MUI.Typography variant="body2">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span
                  style={{
                    userSelect: 'text',
                    cursor: 'text',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <IPAText text={userSkill.skill.name} />
                </span>
              </MUI.Typography>
            </MUI.Box>
          </UnstyledAccordionSummary>
          <UnstyledAccordionDetails>
            <MUI.Box width="100%" padding={1} paddingTop={0}>
              <MUI.Divider style={{ marginBottom: 5 }} />
              <MUI.Box display="flex" flexDirection="row" gridColumnGap={15}>
                <PlotContainer
                  drawPlot={drawPlot}
                  style={{ height: 250, flexGrow: 1 }}
                  onPointerChange={setPointerID}
                />
                <MUI.Box width={250} style={{ overflow: 'hidden' }}>
                  <React.Suspense fallback={<SkillAssessmentDetailsLoading />}>
                    {pointerID ?? lastContinuousAssessment?.id ? (
                      <SkillAssessmentDetails
                        skillAssessmentID={
                          pointerID ?? lastContinuousAssessment?.id ?? ''
                        }
                      />
                    ) : (
                      <SkillAssessmentDetailsLoading />
                    )}
                  </React.Suspense>
                </MUI.Box>
              </MUI.Box>
            </MUI.Box>
          </UnstyledAccordionDetails>
        </MUI.Accordion>
      </MUI.Paper>
    </>
  );
};

const UnstyledAccordionSummary = MUI.withStyles({
  root: {
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MUI.AccordionSummary);

const UnstyledAccordionDetails = MUI.withStyles((_theme) => ({
  root: {
    padding: 0,
  },
}))(MUI.AccordionDetails);

const SkillAssessmentDetails = ({ skillAssessmentID }) => {
  const [debouncedSkillAssessmentID, setDebouncedSkillAssessmentID] =
    useState(skillAssessmentID);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSkillAssessmentID(skillAssessmentID);
    }, 25);

    return () => clearTimeout(timeout);
  }, [skillAssessmentID]);

  const queryRef = useLazyLoadQuery(
    graphql`
      query SkillAssessments_SkillAssessmentDetails_Query(
        $skillAssessmentID: ID!
      ) {
        skillAssessmentByID(id: $skillAssessmentID) {
          id
          rawPosterior

          topAudioLogExamples {
            ...DLPAssessmentsTaken2_SkillAssessmentExample
          }

          bottomAudioLogExamples {
            ...DLPAssessmentsTaken2_SkillAssessmentExample
          }
        }
      }
    `,
    { skillAssessmentID: debouncedSkillAssessmentID },
  ) as any;

  const skillAssessment = queryRef.skillAssessmentByID;

  const drawPlot = useCallback(
    ({ width, height }) => {
      return Plot.plot({
        width,
        height,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        x: {
          label: null,
          axis: null,
          padding: -0.5,
        },
        y: { label: null, axis: null },

        marks: [
          Plot.barY(
            skillAssessment.rawPosterior.map((d, i) => ({
              x: (1 / (skillAssessment.rawPosterior.length - 1)) * i,
              y: d,
            })),
            { x: 'x', y: 'y' },
          ),
          Plot.ruleY([0]),
        ],
      });
    },
    [skillAssessment],
  );

  if (skillAssessmentID !== debouncedSkillAssessmentID) {
    return <SkillAssessmentDetailsLoading />;
  }

  return (
    <MUI.Box display="flex" flexDirection="column" height="100%" gridRowGap={5}>
      {/* {df.format(skillAssessment.createdAt, 'MMM d, yyyy, h:mm a')} */}

      <MUI.Box>
        <MUI.Typography
          variant="overline"
          color="textSecondary"
          style={{ lineHeight: 1.6 }}
        >
          Top Examples (3)
        </MUI.Typography>
        <MUI.Box>
          {skillAssessment.topAudioLogExamples.length === 0 && (
            <MUI.Typography
              variant="body2"
              color="textSecondary"
              style={{ opacity: 0.7 }}
            >
              No examples
            </MUI.Typography>
          )}

          {skillAssessment.topAudioLogExamples.slice(0, 3).map((d, i) => (
            <SkillAssessmentExample
              key={i}
              example={d}
              minWordWidth={80}
              truncate
            />
          ))}
        </MUI.Box>
      </MUI.Box>

      <MUI.Box>
        <MUI.Typography
          variant="overline"
          color="textSecondary"
          style={{ lineHeight: 1.6 }}
        >
          Bottom Examples (3)
        </MUI.Typography>
        <MUI.Box>
          {skillAssessment.bottomAudioLogExamples.length === 0 && (
            <MUI.Typography
              variant="body2"
              color="textSecondary"
              style={{ opacity: 0.7 }}
            >
              No examples
            </MUI.Typography>
          )}

          {skillAssessment.bottomAudioLogExamples.slice(0, 3).map((d, i) => (
            <SkillAssessmentExample
              key={i}
              example={d}
              minWordWidth={80}
              truncate
            />
          ))}
        </MUI.Box>
      </MUI.Box>

      <div style={{ flexGrow: 1 }} />

      <MUI.Box>
        <MUI.Typography
          variant="overline"
          color="textSecondary"
          style={{ lineHeight: 1.6 }}
        >
          Probability
        </MUI.Typography>
        <PlotContainer drawPlot={drawPlot} style={{ height: 20 }} />
      </MUI.Box>
    </MUI.Box>
  );
};

const SkillAssessmentDetailsLoading = () => {
  return (
    <MUI.Box display="flex" flexDirection="column" height="100%" gridRowGap={5}>
      <MUI.Box>
        <MUI.Typography
          variant="overline"
          color="textSecondary"
          style={{ lineHeight: 1.6 }}
        >
          Top Examples (3)
        </MUI.Typography>
        <MUI.Box>
          <MUI.Typography variant="body2">
            <MUISkeleton animation="wave" variant="text" width="80%" />
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>

      <MUI.Box>
        <MUI.Typography
          variant="overline"
          color="textSecondary"
          style={{ lineHeight: 1.6 }}
        >
          Bottom Examples (3)
        </MUI.Typography>
        <MUI.Typography variant="body2">
          <MUISkeleton animation="wave" variant="text" width="80%" />
        </MUI.Typography>
      </MUI.Box>

      <div style={{ flexGrow: 1 }} />

      <MUI.Box>
        <MUI.Typography
          variant="overline"
          color="textSecondary"
          style={{ lineHeight: 1.6 }}
        >
          Probability
        </MUI.Typography>

        <MUISkeleton animation="wave" variant="rect" height={20} />
      </MUI.Box>
    </MUI.Box>
  );
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default SkillAssessments;
