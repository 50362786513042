/**
 * @generated SignedSource<<43bf19f9e88d753b1bcc6578c46a8154>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillAssessments_OverallSkill_user$data = {
  readonly accurateAggregateAssessments: ReadonlyArray<{
    readonly bottomAvg: number | null | undefined;
    readonly createdAt: any | null | undefined;
  }> | null | undefined;
  readonly aggregateAssessments: ReadonlyArray<{
    readonly bottomAvg: number | null | undefined;
    readonly createdAt: any | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "SkillAssessments_OverallSkill_user";
};
export type SkillAssessments_OverallSkill_user$key = {
  readonly " $data"?: SkillAssessments_OverallSkill_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillAssessments_OverallSkill_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bottomAvg",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillAssessments_OverallSkill_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AggregateAssessment",
      "kind": "LinkedField",
      "name": "aggregateAssessments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AggregateAssessment",
      "kind": "LinkedField",
      "name": "accurateAggregateAssessments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "479bb56edb06e50f3bdc3063464ab3fc";

export default node;
