import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import graphql from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React, { useCallback, useMemo, useState, useTransition } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from 'react-relay';

import bvlogo from '../../src/assets/boldvoice_logo.png';
import salogo from '../../src/assets/speechace_logo.png';
import DLPCurriculumItem from '../components/DLPCurriculumItem';
import { SUB_STATUS_LIST } from '../users/UserList';
import DLPAssessmentsTaken2 from './DLPAssessmentsTaken2';

const latestModelOptions = [
  { id: 'speechace', name: 'Speechace' },
  { id: 'boldvoice', name: 'BoldVoice' },
];

const DLPExplorer = () => {
  const data: any = useLazyLoadQuery(
    graphql`
      query DLPExplorer_Query {
        ...DLPExplorer_UsersSelect_users
        ...DLPExplorer_UsersSelect_allLanguages
      }
    `,
    {},
  );

  const [selectedUserID, setSelectedUserID] = useState<string | null>(null);

  const handleUserSelect = useCallback((userID: string) => {
    setSelectedUserID(userID);
  }, []);

  return (
    <>
      <MUI.Box display="flex" height="100%" paddingY={2} gridGap={10}>
        <MUI.Box
          style={{ display: 'flex', flexDirection: 'column' }}
          width={400}
        >
          <UsersSelect
            query={data}
            onUserSelect={handleUserSelect}
            selectedUserID={selectedUserID}
          />
        </MUI.Box>
        <MUI.Divider
          orientation="vertical"
          flexItem
          style={{ marginLeft: 10, marginRight: 10 }}
        />
        <MUI.Box
          style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          width={400}
        >
          <React.Suspense
            fallback={
              <MUI.Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <MUI.CircularProgress />
              </MUI.Box>
            }
          >
            {selectedUserID && (
              <UserDisplay userID={selectedUserID} key={selectedUserID} />
            )}
          </React.Suspense>
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

const UsersSelect = ({ query: queryRef, selectedUserID, onUserSelect }) => {
  const [_isPending, startTransition] = useTransition();

  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment(
      graphql`
        fragment DLPExplorer_UsersSelect_users on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 30 }
          cursor: { type: "String" }
          languages: { type: "[String!]" }
          subStatuses: { type: "[String!]" }
          search: { type: "String" }
          latestModel: { type: "String" }
        )
        @refetchable(queryName: "DLPExplorerUsersSelectPaginationQuery") {
          usersForDLPExplorer(
            first: $count
            after: $cursor
            orderBy: CREATED_AT
            languages: $languages
            subStatuses: $subStatuses
            search: $search
            latestModel: $latestModel
          ) @connection(key: "DLPExplorer_usersForDLPExplorer") {
            edges {
              node {
                id
                ...DLPExplorer_User_user
              }
            }
          }
        }
      `,
      queryRef,
    );

  const onEndReached = useCallback(() => {
    if (!isLoadingNext) {
      loadNext(30);
    }
  }, [isLoadingNext, loadNext]);

  const userEdges = data.usersForDLPExplorer.edges;

  const [selectedLanguage, setSelectedLanguage] = useState<any>([]);
  const [selectedLatestModel, setSelectedLatestModel] = useState<any>('');
  const [selectedSubStatuses, setSelectedSubStatuses] = useState<any>([]);
  const [searchText, setSearchText] = useState<string | null>(null);
  const debouncedSetSearchText = React.useMemo(
    () => _.debounce(setSearchText, 500),
    [],
  );

  const allLanguagesData = useFragment(
    graphql`
      fragment DLPExplorer_UsersSelect_allLanguages on Query {
        allLanguages {
          code
          englishName
        }
      }
    `,
    queryRef,
  );

  React.useEffect(() => {
    startTransition(() => {
      refetch({
        languages: selectedLanguage,
        search: searchText,
        subStatuses: selectedSubStatuses,
        latestModel: selectedLatestModel,
      });
    });
  }, [
    selectedLanguage,
    selectedSubStatuses,
    refetch,
    searchText,
    selectedLatestModel,
  ]);

  return (
    <>
      <Autocomplete
        id="combo-box-languages"
        multiple
        options={allLanguagesData.allLanguages}
        getOptionLabel={(option: any) => option.englishName}
        size="small"
        style={{ marginBottom: 10 }}
        autoHighlight
        disableCloseOnSelect
        onChange={(_, value) => setSelectedLanguage(value.map((v) => v.code))}
        renderInput={(params) => (
          <MUI.TextField
            {...params}
            label="Native Language"
            variant="outlined"
          />
        )}
      />
      <Autocomplete
        id="combo-box-sub-status"
        options={SUB_STATUS_LIST}
        getOptionLabel={(option: any) => option.name}
        size="small"
        multiple
        style={{ marginBottom: 10 }}
        autoHighlight
        disableCloseOnSelect
        onChange={(_, value) => {
          setSelectedSubStatuses(value.map((v) => v.id));
        }}
        renderInput={(params) => (
          <MUI.TextField {...params} label="Sub Status" variant="outlined" />
        )}
      />
      <MUI.TextField
        label="Search for Email or UUIDs"
        variant="outlined"
        size="small"
        style={{ marginBottom: 10 }}
        onChange={(e) => debouncedSetSearchText(e.target.value)}
      />
      <Autocomplete
        id="combo-box-latest-model"
        options={latestModelOptions}
        getOptionLabel={(option: any) => option.name}
        size="small"
        style={{ marginBottom: 10 }}
        autoHighlight
        onChange={(_, value) => setSelectedLatestModel(value?.id)}
        renderInput={(params) => (
          <MUI.TextField {...params} label="Latest Model" variant="outlined" />
        )}
      />

      <MUI.Box style={{ overflowY: 'scroll', height: 0, flexGrow: 1 }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={onEndReached}
          hasMore={hasNext}
          loader={
            <MUI.Box key={0} display="flex" justifyContent="center">
              <MUI.CircularProgress />
            </MUI.Box>
          }
          useWindow={false}
        >
          <MUI.Box display="flex" flexDirection="column" gridRowGap={4}>
            {userEdges.map((userEdge) => (
              <User
                key={userEdge.node.id}
                user={userEdge.node}
                onClick={() => onUserSelect(userEdge.node.id)}
                selected={selectedUserID === userEdge.node.id}
              />
            ))}
          </MUI.Box>
        </InfiniteScroll>
      </MUI.Box>
    </>
  );
};

const User = ({ user: userRef, onClick, selected }) => {
  const data = useFragment(
    graphql`
      fragment DLPExplorer_User_user on User {
        id
        email

        firstName
        lastName

        createdAt
        subStatus
        isSubscriber

        latestModel

        languages {
          englishName
        }

        activeCurriculum {
          lastCompletedItem {
            order
          }
        }

        lastAssessmentTaken {
          id
        }
      }
    `,
    userRef,
  );

  const createdAtString = React.useMemo(
    () =>
      new Date(data.createdAt).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
    [data.createdAt],
  );

  const formattedSubStatus = useMemo(() => {
    return SUB_STATUS_LIST.find((i) => i.id === data.subStatus);
  }, [data.subStatus]);

  return (
    <MUI.Paper
      style={{
        padding: 10,
        ...(selected && { backgroundColor: MUIColors.grey[100] }),
      }}
      variant="outlined"
      onClick={onClick}
      className="MuiPaper-hover"
    >
      <MUI.Box display="flex" justifyContent="space-between">
        <MUI.Typography variant="body2">
          {data.firstName} {data.lastName}
        </MUI.Typography>
        <MUI.Typography variant="body2" color="textSecondary">
          Joined {createdAtString}
        </MUI.Typography>
      </MUI.Box>
      <MUI.Typography
        variant="body2"
        color="textSecondary"
        style={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}
      >
        {data.email ?? 'No Email'}
      </MUI.Typography>
      <MUI.Box
        marginTop={0.5}
        gridColumnGap={8}
        display="flex"
        flexWrap="wrap"
        gridRowGap={5}
      >
        {data.languages?.map((lang) => (
          <MUI.Chip label={lang.englishName} size="small" />
        ))}

        {data.lastAssessmentTaken && (
          <MUI.Chip
            label="Assessment"
            size="small"
            style={{ backgroundColor: MUIColors.amber[100] }}
          />
        )}

        {data.activeCurriculum?.lastCompletedItem && (
          <MUI.Chip
            label={`Completed ${data.activeCurriculum?.lastCompletedItem?.order + 1}`}
            size="small"
            style={{ backgroundColor: MUIColors.green[100] }}
          />
        )}

        {data.subStatus !== 'never_subscribed' && (
          <MUI.Chip
            icon={<MUIIcons.MonetizationOn />}
            label={formattedSubStatus?.name}
            size="small"
            style={{
              backgroundColor:
                formattedSubStatus?.category === 'good'
                  ? MUIColors.green[100]
                  : formattedSubStatus?.category === 'medium'
                    ? MUIColors.orange[100]
                    : formattedSubStatus?.category === 'bad'
                      ? MUIColors.red[100]
                      : '',
            }}
          />
        )}

        <div>
          <img
            alt="logo"
            src={data.latestModel === 'boldvoice' ? bvlogo : salogo}
            style={{ height: 20, width: '100%' }}
          />
        </div>
      </MUI.Box>
    </MUI.Paper>
  );
};

const UserDisplayQuery = graphql`
  query DLPExplorer_UserDisplay_Query($userID: ID!) {
    userByID(id: $userID) {
      id

      email
      firstName
      lastName
      subStatus
      lastSeen
      region
      city
      countryCode

      languages {
        englishName
      }

      activeCurriculum {
        ...DLPExplorer_Curriculum_curriculum
      }
    }
  }
`;

const UserDisplay = ({ userID }) => {
  const data: any = useLazyLoadQuery(UserDisplayQuery, { userID });

  const formattedSubStatus = useMemo(() => {
    return SUB_STATUS_LIST.find((i) => i.id === data.userByID.subStatus);
  }, [data.userByID.subStatus]);

  const lastSeenString = React.useMemo(
    () =>
      new Date(data.userByID.lastSeen).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
    [data.userByID.lastSeen],
  );

  return (
    <MUI.Box style={{ display: 'flex', height: '100%' }} gridColumnGap={20}>
      <MUI.Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: 500,
        }}
      >
        <MUI.Box style={{ overflowY: 'scroll', height: 0, flexGrow: 1 }}>
          <MUI.Typography variant="h6" gutterBottom>
            User
          </MUI.Typography>

          <MUI.Box
            display="flex"
            flexDirection="column"
            gridRowGap={4}
            marginBottom={1}
          >
            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>ID</span>
                <Spacer />
                <span>{userID}</span>
              </MUI.Box>
            </MUI.Typography>

            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>Name</span>
                <Spacer />
                <span>
                  {data.userByID.firstName} {data.userByID.lastName}
                </span>
              </MUI.Box>
            </MUI.Typography>

            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>Email</span>
                <Spacer />
                <span>{data.userByID.email}</span>
              </MUI.Box>
            </MUI.Typography>

            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>Languages</span>
                <Spacer />
                <span>
                  {data.userByID.languages
                    .map((lang) => lang.englishName)
                    .join(', ')}
                </span>
              </MUI.Box>
            </MUI.Typography>

            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>Sub Status</span>
                <Spacer />
                <span>{formattedSubStatus?.name}</span>
              </MUI.Box>
            </MUI.Typography>
            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>Last Seen</span>
                <Spacer />
                <span>{lastSeenString}</span>
              </MUI.Box>
            </MUI.Typography>
            <MUI.Typography variant="body1">
              <MUI.Box display="flex" justifyContent="space-between">
                <span>Location</span>
                <Spacer />
                <span>
                  {[
                    data.userByID.city,
                    data.userByID.region,
                    data.userByID.countryCode,
                  ]
                    .filter(Boolean)
                    .join(', ') ?? 'N/A'}
                </span>
              </MUI.Box>
            </MUI.Typography>
          </MUI.Box>

          <MUI.Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              window.open(`/#/users/${userID}`, '_blank');
            }}
            endIcon={<MUIIcons.OpenInNew />}
          >
            User
          </MUI.Button>
          <MUI.Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              window.open(
                `/#/curriculum-playground?userID=${userID}`,
                '_blank',
              );
            }}
            endIcon={<MUIIcons.OpenInNew />}
          >
            Playground
          </MUI.Button>
          <MUI.Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              window.open(
                `/#/audiologs?displayedFilters=%7B%22User.id%22%3Atrue%7D&filter=%7B%22User%22%3A%7B%22id%22%3A%22${userID}%22%7D%7D`,
                '_blank',
              );
            }}
            endIcon={<MUIIcons.OpenInNew />}
          >
            Audio
          </MUI.Button>
          <MUI.Button
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              window.open(`/#/skill-assessments?userID=${userID}`, '_blank');
            }}
            endIcon={<MUIIcons.OpenInNew />}
          >
            Assessments
          </MUI.Button>

          <MUI.Divider style={{ marginTop: 30, marginBottom: 25 }} />

          <MUI.Typography variant="h6" gutterBottom>
            Assessments
          </MUI.Typography>

          <React.Suspense fallback={null}>
            <DLPAssessmentsTaken2 userID={userID} />
          </React.Suspense>
        </MUI.Box>
      </MUI.Box>
      <MUI.Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          flexGrow: 1,
          maxWidth: 500,
        }}
      >
        <MUI.Box style={{ overflowY: 'scroll', height: 0, flexGrow: 1 }}>
          <Curriculum curriculum={data.userByID.activeCurriculum} />
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

const Curriculum = React.memo(({ curriculum: curriculumFragment }: any) => {
  const data = useFragment(
    graphql`
      fragment DLPExplorer_Curriculum_curriculum on DLPCurriculum {
        id
        chapters {
          number
          units {
            number
            items {
              id
              dayNumber

              ...DLPCurriculumItem_dlpCurriculumItem
            }
          }
        }
      }
    `,
    curriculumFragment,
  );

  return (
    <MUI.Box gridRowGap={25} display="flex" flexDirection="column">
      {data.chapters.map((chapter: any, i: number) => (
        <MUI.Box key={chapter.number}>
          {i !== 0 && <MUI.Divider />}
          <MUI.Typography
            variant="subtitle1"
            style={{ fontWeight: 500, marginTop: 5 }}
          >
            <MUI.Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>Chapter {chapter.number}</span>
              <MUIIcons.MenuBook
                fontSize="inherit"
                style={{ marginBottom: -2, marginRight: 4 }}
              />
            </MUI.Box>
          </MUI.Typography>
          {chapter.units.map((unit: any, unitIndex) => (
            <MUI.Box key={unit.number} marginTop={3}>
              <MUI.Divider />
              <MUI.Typography
                gutterBottom
                variant="subtitle2"
                style={{ marginTop: 5, marginBottom: 10 }}
              >
                <MUI.Typography color="textSecondary" variant="inherit">
                  Chapter {chapter.number},{' '}
                </MUI.Typography>
                Unit {unit.number}
              </MUI.Typography>
              {Object.entries(_.groupBy(unit.items, 'dayNumber')).map(
                ([day, items]) => (
                  <MUI.Box key={day} marginTop={1}>
                    <MUI.Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Day {day}
                    </MUI.Typography>
                    {items.map((item) => (
                      <DLPCurriculumItem
                        key={item.id}
                        curriculumItem={item}
                        isEndOfChapter={unitIndex === chapter.units.length - 1}
                      />
                    ))}
                  </MUI.Box>
                ),
              )}
            </MUI.Box>
          ))}
        </MUI.Box>
      ))}
    </MUI.Box>
  );
});

const Spacer = () => (
  <div
    style={{
      flex: 1,
      borderBottom: '1.5px dotted rgba(0, 0, 0, 20%)',
      height: '1em',
      margin: '0 5px',
    }}
  />
);

export default DLPExplorer;
