import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';
import {
  BooleanInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';
import { JsonField, JsonInput } from 'react-admin-json-view';
import ReactAudioPlayer from 'react-audio-player';

import api from '../api';

const PracticeTitle: FC<FieldProps> = ({ record }) =>
  record ? (
    <span>
      Practice &quot;
      {record.word}&quot;
    </span>
  ) : null;

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  url: { display: 'block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const PracticeForm = (props: any) => {
  const classes = useStyles(props);
  const { record } = props;
  const [audioFile, setAudioFile] = React.useState<any>();
  const [aiAudioFile, setAIAudioFile] = React.useState<any>();
  const [error, setError] = React.useState<any>();
  const [refreshingCoachSpeechace, setRefreshingCoachSpeechace] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const getAudioFile = async (sample: string) =>
      /* eslint-disable-next-line */
      new Promise(async (resolve) => {
        setError(undefined);
        try {
          const newAudioFile = await api.getCoachAudio(
            `${encodeURIComponent(sample)}`,
          );
          if (newAudioFile.status === 403) {
            const jsonResult = await newAudioFile.json();
            if (!jsonResult?.success) {
              setError('No audio file at URL provided.');
              resolve(null);
            }
          } else {
            const fileBlob = await newAudioFile?.body?.blob();
            const reader = new FileReader();
            reader.onload = function (e) {
              const base64audio = e?.target?.result;
              if (base64audio) {
                resolve(base64audio);
              } else {
                setError('No audio file at URL provided.');
                resolve(null);
              }
            };
            reader.readAsDataURL(fileBlob);
          }
        } catch (err: any) {
          setError(err?.message);
          resolve(null);
        }
      });

    const getFiles = async () => {
      if (record?.coachSample) {
        const coachSample = await getAudioFile(record?.coachSample);
        if (coachSample) {
          setAudioFile(coachSample);
        }
      }
      if (record?.aiCoachSample) {
        const aiCoachSample = await getAudioFile(record?.aiCoachSample);
        if (aiCoachSample) {
          setAIAudioFile(aiCoachSample);
        }
      }
    };

    getFiles();

    return () => {
      setAudioFile(null);
      setAIAudioFile(null);
    };
  }, [record?.coachSample, record?.aiCoachSample]);

  const refreshCoachSpeechace = async () => {
    setRefreshingCoachSpeechace(true);
    try {
      const refreshedStatus = await api.refreshCoachspeechace(record.id);
      if (refreshedStatus.status === 200) {
        alert('Successfully refreshed coach speechace');
        window.location.reload();
      }
    } catch (err) {
      alert('Unable to refresh, an error occurred');
    } finally {
      setRefreshingCoachSpeechace(false);
    }
  };

  const onError = (newError: any) => {
    setError(newError?.message);
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <TextInput
                    autoFocus
                    source="word"
                    label="Practice Text"
                    formClassName={classes.name}
                    fullWidth
                    multiline
                  />
                </Box>
                <Box>
                  <TextInput
                    source="markedUpText"
                    label="Practice Text w/ Markup (if entered this gets sent to SpeechAce)"
                    formClassName={classes.name}
                    fullWidth
                    multiline
                  />
                </Box>
                <Box>
                  <TextInput
                    source="syllables"
                    label="Syllables"
                    formClassName={classes.other}
                    fullWidth
                  />
                </Box>
                <Box>
                  <TextInput
                    source="ipaFull"
                    label="IPA Spelling"
                    formClassName={classes.other}
                    fullWidth
                  />
                </Box>
                {/* <Box>
                  <TextInput
                    source="phonemeList"
                    label="Phoneme List"
                    formClassName={classes.other}
                    fullWidth
                  />
                </Box> */}
                <Box>
                  <ReferenceInput
                    allowEmpty
                    label="Coach"
                    source="coachId"
                    reference="coach"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Box>
                <Box>
                  <TextInput
                    source="coachSample"
                    label="AWS S3 Filename"
                    formClassName={classes.other}
                    fullWidth
                  />
                </Box>
                {record.coachSpeechace && (
                  <Box>
                    <JsonField
                      source="qualityScore"
                      record={{
                        ...record,
                        qualityScore: {
                          value: JSON.parse(record.coachSpeechace)
                            ?.quality_score,
                        },
                      }}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'Coach Score',
                        collapsed: false,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                  </Box>
                )}
                {record.coachSpeechace && (
                  <Box>
                    <JsonField
                      source="phonemeListWithExpectedStress"
                      record={{
                        ...record,
                        phonemeListWithExpectedStress: JSON.parse(
                          record.coachSpeechace,
                        )?.phonemeListWithExpectedStress,
                      }}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'phonemeListWithExpectedStress',
                        collapsed: false,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                  </Box>
                )}
                {record.coachSpeechace && (
                  <Box>
                    <JsonField
                      source="phonemeListWithHeardStress"
                      record={{
                        ...record,
                        phonemeListWithHeardStress: JSON.parse(
                          record.coachSpeechace,
                        )?.phonemeListWithHeardStress,
                      }}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'phonemeListWithHeardStress',
                        collapsed: false,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                  </Box>
                )}

                {record.coachSpeechace && (
                  <Box>
                    <JsonField
                      source="coachSpeechace"
                      record={{
                        ...record,
                        coachSpeechace: JSON.parse(record.coachSpeechace),
                      }}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'coachSpeechace',
                        collapsed: true,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                  </Box>
                )}

                <Box>
                  <Button
                    onClick={refreshCoachSpeechace}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    {`${
                      record.coachSpeechace ? `Refresh` : `Generate`
                    } coach speechace`}
                  </Button>
                  {refreshingCoachSpeechace && (
                    <CircularProgress style={{ marginLeft: 10 }} size={20} />
                  )}
                </Box>

                {audioFile && !error && (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Audio File
                    </Typography>
                    <ReactAudioPlayer controls onError={onError}>
                      <source src={audioFile} />
                    </ReactAudioPlayer>
                  </Box>
                )}
                <Box>
                  <TextInput
                    source="aiCoachSample"
                    label="AWS S3 Filename (AI/Polly)"
                    formClassName={classes.other}
                    fullWidth
                  />
                </Box>
                {aiAudioFile && !error && (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      AI Audio File
                    </Typography>
                    <ReactAudioPlayer controls onError={onError}>
                      <source src={aiAudioFile} />
                    </ReactAudioPlayer>
                  </Box>
                )}

                {error && (
                  <Typography variant="body1" gutterBottom>
                    Coach Sample Error: {error}
                  </Typography>
                )}

                <Box>
                  <BooleanInput
                    source="isSentence"
                    label="Is Sentence?"
                    formClassName={classes.other}
                  />
                </Box>
                <Box>
                  <BooleanInput
                    source="isInstruction"
                    label="Is Instruction?"
                    formClassName={classes.other}
                  />
                </Box>
                <Box>
                  <BooleanInput
                    source="uploadToSpeechAce"
                    label="Upload To SpeechAce?"
                    formClassName={classes.other}
                  />
                </Box>
              </Box>
              {record.definition && (
                <Box>
                  <JsonInput
                    source="definition"
                    record={record}
                    jsonString={false}
                    reactJsonOptions={{
                      name: 'definition',
                      collapsed: true,
                      enableClipboard: true,
                      displayDataTypes: false,
                      sortKeys: true,
                      displayObjectSize: false,
                    }}
                  />
                </Box>
              )}

              {record.translations?.length ? (
                <Box>
                  <JsonInput
                    source="translations"
                    record={record}
                    jsonString={false}
                    reactJsonOptions={{
                      name: 'translations',
                      collapsed: true,
                      enableClipboard: true,
                      displayDataTypes: false,
                      sortKeys: true,
                      displayObjectSize: false,
                    }}
                  />
                </Box>
              ) : null}

              {record.lyricsDuration?.length ? (
                <Box>
                  <JsonInput
                    source="lyricsDuration"
                    record={record}
                    jsonString={false}
                    reactJsonOptions={{
                      name: 'Lyrics and Durations',
                      enableClipboard: true,
                      displayDataTypes: false,
                      sortKeys: true,
                      displayObjectSize: false,
                    }}
                  />
                </Box>
              ) : null}
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                undoable={false}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="practices"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <SaveButton
                    label="Save & Continue"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect="edit"
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />

                  <SaveButton
                    label="Save & Exit"
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect ||
                      formProps.handleSubmit
                    }
                    disabled={formProps.disabled}
                    invalid={formProps.invalid}
                    redirect={formProps.redirect}
                    saving={formProps.saving}
                    submitOnEnter={formProps.submitOnEnter}
                    className={classes.button}
                  />
                </div>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </Toolbar>
            </CardContent>
          </form>
        </Card>
      )}
    />
  );
};

const PracticeEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<PracticeTitle />} {...props}>
    <PracticeForm />
  </Edit>
);

export default PracticeEdit;
