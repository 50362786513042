import * as MUI from '@material-ui/core';
import * as MUIColors from '@material-ui/core/colors';
import * as MUIIcons from '@material-ui/icons';
import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import { useFragment } from 'react-relay';

import IPAText from '../components/IPAText';

const CURRICULUM_ITEM_TYPES = {
  VIDEO: {
    label: 'Video',
    icon: MUIIcons.OndemandVideo,
    color: MUIColors.yellow[800],
  },
  FOCUS_WORD: {
    label: 'Focus Word',
    icon: MUIIcons.OndemandVideo,
  },
  IDIOM: {
    label: 'Idiom',
    icon: MUIIcons.OndemandVideo,
  },
  PRACTICE: {
    label: 'Practice',
    icon: MUIIcons.MicNone,
    color: MUIColors.deepPurple[400],
  },
  CONVERSATION: {
    label: 'Conversation',
    icon: MUIIcons.Chat,
    color: MUIColors.red[400],
  },
  QUIZ: {
    label: 'Quiz',
    icon: MUIIcons.PlaylistAddCheck,
    color: MUIColors.green[600],
  },
  AI_CHAT: {
    label: 'AI Chat',
    icon: MUIIcons.Forum,
    color: MUIColors.blue[500],
  },
  ASSESSMENT: {
    label: 'Assessment',
    icon: MUIIcons.CheckCircleSharp,
    color: '#5965EB',
  },
};

const DLPCurriculumItem = ({
  curriculumItem: curriculumItemRef,
  isEndOfChapter,
}: any) => {
  const data = useFragment(
    graphql`
      fragment DLPCurriculumItem_dlpCurriculumItem on DLPCurriculumItem {
        type
        optional
        status

        completedAt

        skill {
          id
          name
        }

        video {
          title
        }

        conversation {
          name
        }

        practices {
          text
        }

        assessment {
          averageScore
          updatedAt
        }
      }
    `,
    curriculumItemRef,
  );

  const curriculumItemTypeConfig = CURRICULUM_ITEM_TYPES[data.type];
  const Icon = curriculumItemTypeConfig?.icon || (() => {});

  return (
    <MUI.Box marginBottom={0.5}>
      <MUI.Paper
        style={{
          padding: 5,
          ...(data.status === 'COMPLETED'
            ? {
                backgroundColor: MUIColors.grey[100],
              }
            : data.status === 'IN_PROGRESS'
              ? {
                  backgroundColor: MUIColors.green[50],
                }
              : {}),
        }}
        variant="outlined"
      >
        {data.skill && (
          <>
            <MUI.Box
              display="flex"
              justifyContent="space-between"
              style={{ opacity: 0.6 }}
              marginBottom={0.5}
            >
              <MUI.Typography variant="body2">
                <MUI.Link
                  href={`/#/g/skills/${data.skill.id}`}
                  target="_blank"
                  color="inherit"
                >
                  <IPAText text={data.skill.name} />
                </MUI.Link>
              </MUI.Typography>
            </MUI.Box>
            <MUI.Divider />
          </>
        )}

        <MUI.Box display="flex" alignItems="center" marginTop={0.5}>
          <MUI.Tooltip
            title={CURRICULUM_ITEM_TYPES[data.type].label}
            placement="left"
          >
            <Icon
              fontSize="small"
              style={{ marginRight: 8 }}
              htmlColor={curriculumItemTypeConfig?.color}
            />
          </MUI.Tooltip>

          <MUI.Typography variant="body1">
            {data.type === 'ASSESSMENT' ? (
              <>
                SpeechScan{' '}
                <MUI.Typography color="textSecondary" display="inline">
                  {Math.round(data.assessment.averageScore * 100)}%{' '}
                </MUI.Typography>
              </>
            ) : (
              <IPAText
                text={
                  data?.video?.title ??
                  data?.conversation?.name ??
                  (data.type === 'AI_CHAT'
                    ? 'Unit AI Chat'
                    : data.type === 'QUIZ'
                      ? isEndOfChapter
                        ? 'Chapter Quiz'
                        : 'Unit Quiz'
                      : data.assessment
                        ? 'SpeechScan Practice Set'
                        : null)
                }
              />
            )}
          </MUI.Typography>
        </MUI.Box>

        {data?.practices && (
          <MUI.Box marginTop={1} marginRight={1} display="inline-block">
            <MUI.Tooltip
              placement="left"
              arrow
              title={
                <>
                  {data.practices.map((practice: any) => (
                    <div key={practice.text}>{practice.text}</div>
                  ))}
                </>
              }
            >
              <MUI.Chip
                label={`${data.practices.length} practices`}
                size="small"
              />
            </MUI.Tooltip>
          </MUI.Box>
        )}

        {data?.optional && (
          <MUI.Box marginTop={1} display="inline-block">
            <MUI.Chip
              label="Optional"
              size="small"
              style={{ backgroundColor: MUIColors.orange[100] }}
              icon={<MUIIcons.DoubleArrow />}
            />
          </MUI.Box>
        )}

        {data.status && data.status !== 'NOT_STARTED' && (
          <MUI.Box marginTop={1} display="inline-block">
            <MUI.Tooltip
              arrow
              title={
                data.completedAt &&
                moment(data.completedAt).format('MMM Do YYYY h:mma')
              }
              disableFocusListener={!data.completedAt}
              disableHoverListener={!data.completedAt}
              disableTouchListener={!data.completedAt}
            >
              <MUI.Chip
                label={
                  data.status === 'COMPLETED' ? 'Completed' : 'In Progress'
                }
                size="small"
                style={{ backgroundColor: MUIColors.green[100] }}
                icon={
                  data.status === 'COMPLETED' ? (
                    <MUIIcons.Check />
                  ) : (
                    <MUIIcons.PlayArrow />
                  )
                }
              />
            </MUI.Tooltip>
          </MUI.Box>
        )}
      </MUI.Paper>
    </MUI.Box>
  );
};

export default DLPCurriculumItem;
